import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useEffect, useState } from 'react';

import { ProfileAction, UserClaims } from '@agerpoint/types';

import { hasClaims } from '../claims';
import { useGlobalStore } from './store';

export const useProfile = (): ProfileAction[] => {
  const auth0 = useAuth0();

  const { user } = useGlobalStore();
  const [extraItems, setExtraItems] = useState<ProfileAction[]>([]);

  const signOutLocal = () => {
    mixpanel.reset();
    auth0.logout({
      logoutParams: {
        returnTo: window.location.origin,
        search: window.location.search,
      },
    });
  };

  const adminItem = {
    label: 'Platform',
    link: '/admin',
    disabled: false,
  };

  const qaqcItem = {
    label: 'Operations',
    link: '/ops',
    disabled: false,
  };

  const standAloneViewerItem = {
    label: '3D Viewer',
    link: '/viewer',
    disabled: false,
  };

  useEffect(() => {
    const userClaims = user?.cloudClaims as UserClaims[];
    const extra = [];
    if (hasClaims([UserClaims.AgerAdmin], userClaims)) {
      extra.push(adminItem);
      extra.push(standAloneViewerItem);
    }

    if (hasClaims([UserClaims.QAQC], userClaims)) {
      extra.push(qaqcItem);
    }
    setExtraItems(extra);
  }, [user]);

  return [
    ...extraItems,
    {
      label: 'Profile',
      link: `/profile`,
      disabled: false,
    },
    {
      label: 'Sign Out',
      action: signOutLocal,
      disabled: false,
    },
  ];
};
