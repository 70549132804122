import { useAuth0 } from '@auth0/auth0-react';
import mixpanel from 'mixpanel-browser';
import { useRef, useState } from 'react';

import { useAgerStore, useCloudNavigate } from '@agerpoint/utilities';

import { ContextMenu } from '../context-menu';

export const ProfileButton = () => {
  const {
    user: { userInfo, isAdmin, isQAQC },
  } = useAgerStore();

  const buttonRef = useRef<HTMLDivElement>(null);
  const auth0 = useAuth0();
  const [singingOut, setSigningOut] = useState(false);

  const navigate = useCloudNavigate();

  const [showContextMenu, setShowContextMenu] = useState(false);

  return (
    <>
      <div
        className={`w-10 h-10 bg-tertiary border-transparent font-bold transition-all rounded-full select-none
      duration-200 shadow-sm focus:outline-none outline-none flex-shrink-0
      flex flex-row items-center justify-center relative text-white cursor-pointer hover-overlay-20`}
        tabIndex={-1}
        ref={buttonRef}
        data-test-id="profile-button"
      >
        {userInfo?.givenName?.charAt(0)?.toUpperCase()}
      </div>
      <ContextMenu
        show={showContextMenu}
        setShow={setShowContextMenu}
        childRef={buttonRef}
        bonusPadding={{
          top: 5,
        }}
        dontHideOnBodyClick
      >
        <ContextMenu.Content>
          {/* <ContextMenu.Item label="Profile" icon={'user'} disabled /> */}
          {(isAdmin || isQAQC) && (
            <ContextMenu.Item
              icon={'screwdriver-wrench'}
              label="Admin Panel"
              onClick={(e) => {
                navigate(
                  {
                    pathname: '/app/admin',
                  },
                  {
                    mouseEvent: e,
                  }
                );
              }}
            />
          )}
          <ContextMenu.Item
            label="Sign out"
            icon={'sign-out'}
            loading={singingOut}
            onClick={(e) => {
              mixpanel.reset();
              auth0.logout({
                logoutParams: {
                  returnTo: window.location.origin,
                  search: window.location.search,
                },
              });
              setSigningOut(true);
            }}
          />
        </ContextMenu.Content>
      </ContextMenu>
    </>
  );
};
