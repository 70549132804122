import mixpanel from 'mixpanel-browser';
import { useCallback, useRef } from 'react';

import { MixpanelNames } from '@agerpoint/types';

import { useAgerStore } from '../agerStore';
import { getCurrentEnvironment } from '../environments/environment.utilities';
import { isMobileTablet } from '../isMobileTablet/isMobileTablet';

export const useMixpanel = () => {
  const {
    user: { userInfo },
  } = useAgerStore();

  const debugMode = useRef<boolean>(false);

  const sendEvent = useCallback(
    (eventName: MixpanelNames, eventBody?: object) => {
      const isMobile = isMobileTablet();

      const body = {
        ...eventBody,
        env: getCurrentEnvironment(),
        userId: userInfo?.userId ?? 'anonymous',
        userName: userInfo?.email ?? 'anonymous',
        isMobile,
      };

      if (debugMode && window.location.hostname === 'localhost') {
        console.info('Mixpanel Event:', eventName, body);
      }

      mixpanel.track(eventName, body);
    },
    [userInfo]
  );

  return {
    sendEvent,
  };
};
