import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo } from 'react';

interface ITabs {
  tab: string | undefined | null;
  tabMapping: ITabMapping;
  padding?: string;
  title?: React.ReactNode;
  defaultTab: string;
}

export interface ITabMapping {
  [key: string]: {
    title: string;
    titleIcon?: IconName;
    content: React.ReactNode;
    onClick?: (e: React.MouseEvent) => void;
  };
}

export const Tabs = ({
  tab: tabProp,
  tabMapping,
  padding,
  title,
  defaultTab,
}: ITabs) => {
  const tab = useMemo(() => {
    if (!tabProp) {
      return defaultTab;
    }

    if (!(tabProp in tabMapping)) {
      return defaultTab;
    }

    return tabProp;
  }, [tabProp, defaultTab, tabMapping]);

  return (
    <div className="flex flex-col size-full">
      <div
        className={`flex flex-row justify-between relative ${
          padding ?? ''
        }`.trim()}
      >
        {title && (
          <div className="text-lg font-bold py-2 px-4 flex-center">{title}</div>
        )}
        <div className="flex flex-row">
          {Object.entries(tabMapping).map(([key, value]) => {
            const isSelected = tab === key;

            return (
              <div
                key={key}
                className={`px-2 py-4 relative transition-all ${
                  isSelected
                    ? 'text-primary'
                    : 'cursor-pointer hover:bg-gray-background rounded-t-lg'
                }`}
                onClick={value.onClick}
              >
                <div className="flex flex-row gap-1 items-center text-sm">
                  {value.titleIcon && (
                    <div className="size-5 flex-center">
                      <FontAwesomeIcon
                        icon={[isSelected ? 'fas' : 'far', value.titleIcon]}
                      />
                    </div>
                  )}
                  <div>{value.title}</div>
                </div>
                <AnimatePresence>
                  {isSelected && (
                    <motion.div
                      layoutId="tab-indicator"
                      className="absolute bottom-0 h-0.5 inset-x-0 bg-primary rounded-t z-1"
                    />
                  )}
                </AnimatePresence>
              </div>
            );
          })}
        </div>
        <div className="absolute bottom-0 h-px inset-x-0 bg-gray-border rounded" />
      </div>
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={tab}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="size-full overflow-x-hidden overflow-y-auto"
        >
          {tab && (tabMapping[tab]?.content ?? null)}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};
