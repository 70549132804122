import { useAuth0 } from '@auth0/auth0-react';
import { Extent, isEmpty } from 'ol/extent';
import { transformExtent } from 'ol/proj';
import { useEffect, useState } from 'react';
import { shallow } from 'zustand/shallow';

import { ProjectMap as ComponentProjectMap } from '@agerpoint/feature';
import { PanelsState, ProjectState } from '@agerpoint/types';
import { environment } from '@agerpoint/utilities';

import { usePanels } from '../../state/use-panels';
import { bingKey, useProject } from '../../state/use-project';

export default function ProjectMap() {
  const auth0 = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const doAsync = async () => {
      const token = await auth0.getAccessTokenSilently();
      setAccessToken(token);
    };
    doAsync();
  }, [auth0]);
  const { groups, desiredExtent, setDesiredExtent } = useProject(
    getGroups,
    shallow
  );
  const {
    mapMode,
    toolMode,
    selectedFeatureIds,
    selectedLayerId,
    setSelectedFeatureIds,
    setSelectedLayerId,
    isSidebarOpen,
    isExpansionOpen,
    isBottomPanelOpen,
    layerIdUpdateCount,
    selectedOlUid,
    setSelectedOlUid,
  } = usePanels(getPanelsState, shallow);
  const [initialExtent, setInitialExtent] = useState<Extent>();
  useEffect(() => {
    if (!desiredExtent || isEmpty(desiredExtent)) {
      return;
    }
    if (
      desiredExtent[2] - desiredExtent[0] > 0 &&
      desiredExtent[3] - desiredExtent[1] > 0
    ) {
      const projectedExtent = transformExtent(
        desiredExtent,
        'EPSG:4326',
        'EPSG:3857'
      );
      setInitialExtent(projectedExtent);
      // Reset the desired extent so that any update to it,
      // even the one we just set, will force the map to pan.
      setDesiredExtent(undefined);
    }
    if (
      desiredExtent[0] === desiredExtent[2] ||
      desiredExtent[1] === desiredExtent[3]
    ) {
      // handle bbox with same coordinates
      const factor = 0.0006;

      // Adjust the extent based on hemisphere
      const adjustedExtent = [
        Math.min(desiredExtent[0], desiredExtent[2]) - factor, // min X
        Math.min(desiredExtent[1], desiredExtent[3]) - factor, // min Y
        Math.max(desiredExtent[0], desiredExtent[2]) + factor, // max X
        Math.max(desiredExtent[1], desiredExtent[3]) + factor, // max Y
      ];
      const proj = transformExtent(adjustedExtent, 'EPSG:4326', 'EPSG:3857');
      setInitialExtent(proj);
      setDesiredExtent(undefined);
    }
  }, [desiredExtent, setDesiredExtent]);

  return (
    <ComponentProjectMap
      mode={mapMode}
      bingKey={bingKey}
      groups={groups}
      initialExtent={initialExtent}
      selectedLayerId={selectedLayerId}
      setSelectedLayerId={setSelectedLayerId}
      selectedFeatureIds={selectedFeatureIds}
      setSelectedFeatureIds={setSelectedFeatureIds}
      showFeatureInfo={toolMode === 'select'}
      margins={[
        0,
        0,
        isBottomPanelOpen ? 320 : 0,
        (isSidebarOpen ? 320 : 0) + (isExpansionOpen ? 400 : 0),
      ]}
      serverUrl={environment.api_server_url || ''}
      token={accessToken || ''}
      layerIdUpdateCount={layerIdUpdateCount}
      selectedOlUid={selectedOlUid}
      setSelectedOlUid={setSelectedOlUid}
    />
  );
}

const getGroups = ({
  groups,
  desiredExtent,
  actions: { setDesiredExtent },
}: ProjectState) => ({
  groups,
  desiredExtent,
  setDesiredExtent,
});

function getPanelsState({
  toolMode,
  mapMode,
  selectedFeatureIds,
  selectedLayerId,
  setSelectedFeatureIds,
  setSelectedLayerId,
  isSidebarOpen,
  currentExpansionId,
  layerIdUpdateCount,
  selectedOlUid,
  setSelectedOlUid,
}: PanelsState) {
  return {
    toolMode,
    mapMode,
    selectedFeatureIds,
    selectedLayerId,
    setSelectedFeatureIds,
    setSelectedLayerId,
    isSidebarOpen,
    isBottomPanelOpen: toolMode === 'attributes',
    isExpansionOpen: currentExpansionId != null,
    layerIdUpdateCount,
    selectedOlUid,
    setSelectedOlUid,
  };
}
