import { useVirtualizer } from '@tanstack/react-virtual';
import { useRef } from 'react';

interface IVirtualizedList {
  children?: React.ReactNode[];
  largestItemSize: number;
  className?: string;
}

export const VirtualizedList = ({
  children,
  largestItemSize,
  className,
}: IVirtualizedList) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const virtualizer = useVirtualizer({
    count: children?.length ?? 0,
    getScrollElement: () => wrapperRef.current,
    estimateSize: () => largestItemSize,
    overscan: 5,
  });

  const items = virtualizer.getVirtualItems();

  return (
    <div
      ref={wrapperRef}
      className={`overflow-x-hidden overflow-y-auto size-full ${className}`.trim()}
    >
      <div
        className="w-full relative"
        style={{
          height: virtualizer.getTotalSize(),
        }}
      >
        <div
          className="absolute top-0 left-0 w-full"
          style={{
            transform: `translateY(${items?.[0]?.start}px)`,
          }}
        >
          {items.map((i) => (
            <div
              key={i.key}
              data-index={i.index}
              ref={virtualizer.measureElement}
            >
              {children?.[i.index]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
