import { BrowserRouter } from 'react-router-dom';

import { AgercloudMainPage } from '@agerpoint/page';

export const App = () => {
  return (
    <BrowserRouter>
      <AgercloudMainPage />
    </BrowserRouter>
  );
};
