import { User } from '@auth0/auth0-react';

import { APIClient } from '@agerpoint/api';

export interface IAgerStoreUserSlice {
  userInfo?: APIClient.UserDto;
  auth?: User;
  setUser: ({
    userInfo,
    auth,
  }: {
    userInfo: APIClient.UserDto;
    auth: User;
  }) => void;
  hasClaim: (claimToCheck: string) => boolean;
  isAdmin: boolean;
  isQAQC: boolean;
}

export const initialAgerStoreUserSlice: IAgerStoreUserSlice = {
  setUser: () => {
    throw new Error('AgerStore not initialized');
  },
  isAdmin: false,
  isQAQC: false,
  hasClaim: () => false,
};
