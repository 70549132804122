import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { APIModels, APIRequests } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import {
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { useAdminFarmsQueries } from './admin-farms-queries';

export const AdminFarmsNew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  usePageTitle(() => 'Platform - Farms', []);

  const isViteApp = useIsViteApp();

  const [farm, setFarm] = useState<Partial<APIModels.Farm>>({});
  const [selectedOrganization, setSelectedOrganization] =
    useState<APIModels.Customer>();
  const [selectedCountry, setSelectedCountry] = useState<APIModels.Country>();
  const [selectedRegion, setSelectedRegion] = useState<APIModels.Region>();

  const formValidation = useFormValidation();

  const {
    farmPostMutation,
    countriesQuery,
    countryRegionsQuery,
    organizationsQuery,
  } = useAdminFarmsQueries(selectedCountry);

  useEffect(() => {
    setFarm((prev) => ({ ...prev, customerId: selectedOrganization?.id }));
  }, [selectedOrganization]);

  useEffect(() => {
    setFarm((prev) => ({ ...prev, regionId: selectedRegion?.id }));
  }, [selectedRegion]);

  const createFarm = useCallback(async () => {
    if (farmPostMutation.isPending) {
      return;
    }

    if (await formValidation.hasErrors()) {
      return;
    }

    farmPostMutation.mutate({
      data: farm as APIModels.Farm,
    });
  }, [farm, formValidation, farmPostMutation]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Farms',
              path: isViteApp ? '/app/admin/platform/farms' : '/admin/farms',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="new-farm-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/farms' + params);
            } else {
              navigate('/admin/farms' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">New Farm</h1>
      </div>
      <div className="p-4 w-full flex flex-col max-w-lg gap-2">
        <Input.Text.Single
          id="farm-name-input"
          value={farm?.farmName || ''}
          setValue={(farmName) => {
            setFarm({ ...farm, farmName });
          }}
          label={<Input.Label label="Name" required />}
          error={
            <Input.Error error={formValidation.errors['farm-name-input']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Name')],
          }}
        />
        <Input.Text.Single
          id="farm-display-name-input"
          value={farm?.farmDisplayName || ''}
          setValue={(farmDisplayName) => {
            setFarm({ ...farm, farmDisplayName });
          }}
          label={<Input.Label label="Display Name" required />}
          error={
            <Input.Error
              error={formValidation.errors['farm-display-name-input']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Display Name')],
          }}
        />
        <Input.Select.Single
          id="farm-organization-select"
          title="Organization"
          options={organizationsQuery.data ?? []}
          loading={organizationsQuery.isLoading}
          optionBuilder={(o) => o?.customerDisplayName ?? 'Unknown'}
          value={selectedOrganization}
          setValue={setSelectedOrganization}
          label={<Input.Label label="Organization" required />}
          error={
            <Input.Error
              error={formValidation.errors['farm-organization-select']}
            />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Organization')],
          }}
        />
        <Input.Text.Single
          id="farm-address-input"
          value={farm?.address || ''}
          setValue={(address) => {
            setFarm({ ...farm, address });
          }}
          label={<Input.Label label="Address" />}
        />
        <Input.Text.Single
          id="farm-city-input"
          value={farm?.city || ''}
          setValue={(city) => {
            setFarm({ ...farm, city });
          }}
          label={<Input.Label label="City" />}
        />
        <Input.Select.Single
          id="farm-country-select"
          title="Country"
          options={countriesQuery.data ?? []}
          optionBuilder={(o) => o?.countryName ?? 'Unknown'}
          loading={countriesQuery.isLoading}
          value={selectedCountry}
          setValue={setSelectedCountry}
          label={<Input.Label label="Country" required />}
          error={
            <Input.Error error={formValidation.errors['farm-country-select']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Country')],
          }}
        />
        <Input.Select.Single
          id="farm-region-select"
          title="Region"
          options={countryRegionsQuery.data ?? []}
          optionBuilder={(o) => o?.regionName ?? 'Unknown'}
          loading={countryRegionsQuery.isLoading}
          value={selectedRegion}
          setValue={setSelectedRegion}
          disabled={
            countryRegionsQuery.isLoading || selectedCountry === undefined
          }
          label={<Input.Label label="Region" required />}
          error={
            <Input.Error error={formValidation.errors['farm-region-select']} />
          }
          validation={{
            validationState: formValidation,
            validators: [Input.validators.required('Region')],
          }}
        />
        <Input.Number.Decimal
          id="farm-acres-input"
          onlyPositive
          value={farm?.totalAcres ?? undefined}
          setValue={(totalAcres) => {
            setFarm({ ...farm, totalAcres });
          }}
          label={<Input.Label label="Total Acres" />}
        />
        <div className="w-full flex flex-row justify-end items-center py-4">
          <Button.Primary
            id="create-farm-button"
            label={'Create'}
            onClick={createFarm}
            loading={farmPostMutation.isPending}
          />
        </div>
      </div>
    </div>
  );
};
