import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseGetReturn } from 'restful-react';

import {
  Capture,
  CaptureExtractionJob,
  useGetCaptureById,
  useGetCaptureExtractionJobsByCaptureId,
} from '@agerpoint/api';
import {
  AllControllersComponent,
  PotreeLayerToggleBarComponent,
  QaqcBottomBars,
  QaqcTopBars,
  useCapturesViewerContext,
} from '@agerpoint/feature';
import { ViewerType } from '@agerpoint/types';
import {
  isGaussianJob,
  useGlobalStore,
  usePageTitle,
} from '@agerpoint/utilities';

export const QaqcJobDetailsPage = () => {
  const auth0 = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const doAsync = async () => {
      const token = await auth0.getAccessTokenSilently();
      setAccessToken(token);
    };
    doAsync();
  }, [auth0]);
  const {
    sidebar: {
      actions: { setIsOpen },
    },
    twoDimensionDrawingIsActive,
  } = useGlobalStore();
  const params = useParams();

  const {
    selectedCaptureJob,
    viewerController,
    setViewerController,
    selectedCaptureExtractionJobId,
  } = useCapturesViewerContext();

  const captureId: string | undefined = params?.captureId;
  const eptId: string | undefined = params?.eptId;

  const { data: capture } = useGetCaptureById({
    id: captureId ? +captureId : NaN,
  }) as unknown as UseGetReturn<Capture, void, void, unknown>;

  usePageTitle(() => {
    return capture?.captureName ? `QAQC - ${capture.captureName}` : undefined;
  }, [capture?.captureName]);

  useEffect(() => {
    setIsOpen(false);
    return () => {
      setIsOpen(true);
    };
  }, [setIsOpen]);

  const { data: extractionJobs } = useGetCaptureExtractionJobsByCaptureId({
    captureId: captureId ? +captureId : NaN,
  }) as unknown as UseGetReturn<CaptureExtractionJob[], void, void, unknown>;

  useEffect(() => {
    if (
      selectedCaptureJob === undefined ||
      (!isGaussianJob(selectedCaptureJob) &&
        !selectedCaptureJob?.eptPointcloudId)
    ) {
      viewerController?.setViewerType(ViewerType.Unavailable);
      return;
    }

    if (isGaussianJob(selectedCaptureJob)) {
      viewerController?.setViewerType(ViewerType.Three);
    } else {
      viewerController?.setViewerType(ViewerType.Potree);
    }
  }, [selectedCaptureJob]);

  return (
    <div className="relative w-full h-full bg-black">
      <QaqcTopBars
        capture={capture}
        extractionJobs={extractionJobs}
        eptId={eptId}
        extId={selectedCaptureExtractionJobId?.toString()}
      />
      <QaqcBottomBars
        extId={selectedCaptureExtractionJobId?.toString()}
        viewerController={viewerController}
      />
      <PotreeLayerToggleBarComponent />
      <AllControllersComponent
        controller={setViewerController}
        token={accessToken}
        potree={{
          showTools: true,
        }}
        three={{
          showTools: true,
          showLoadingIndicator: false,
        }}
      />
      {twoDimensionDrawingIsActive && (
        <div
          className="z-20 absolute top-0 left-0 pointer-events-auto"
          id="konva-root"
          style={{
            width: '100%',
            height: '100%',
          }}
        ></div>
      )}
    </div>
  );
};
