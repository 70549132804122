import { useAuth0 } from '@auth0/auth0-react';
import Feature from 'ol/Feature';
import { getHeight, getWidth } from 'ol/extent';
import MVT from 'ol/format/MVT';
import Geometry from 'ol/geom/Geometry';
import Point from 'ol/geom/Point';
import VectorTileLayer from 'ol/layer/VectorTile';
import { transformExtent } from 'ol/proj';
import TileWMS from 'ol/source/TileWMS';
import VectorSource from 'ol/source/Vector';
import VectorTileSource from 'ol/source/VectorTile';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { APIModels, Extent, formatDateAndTime } from '@agerpoint/api';
import { BreadCrumbs, Button, Input } from '@agerpoint/component';
import { OpenLayerMap } from '@agerpoint/feature';
import {
  LayerTypeName,
  OpenLayerMapController,
  OpenMapLayer,
  WmsVectorMapLayer,
} from '@agerpoint/types';
import {
  environment,
  loadTile,
  toStateLayer,
  useFormValidation,
  useIsViteApp,
  usePageTitle,
} from '@agerpoint/utilities';

import { EntityDetailsSection } from '../../../subcomponents/entity-details-section';
import {
  PageErrorState,
  PageLoadingState,
} from '../../../subcomponents/page-states';
import { useAdminGeometriesQueries } from './admin-geometries-queries';

export const AdminGeometriesDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state?.params ?? '';
  usePageTitle(() => 'Platform - Geometries', []);

  const isViteApp = useIsViteApp();

  const geometryFormValidation = useFormValidation();
  const cloneGeometryFormValidation = useFormValidation();
  const auth0 = useAuth0();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const doAsync = async () => {
      const token = await auth0.getAccessTokenSilently();
      setAccessToken(token);
    };
    doAsync();
  }, [auth0]);
  const { geometryId } = useParams();

  const {
    geometryQuery,
    geometryPutMutation,
    geometryArchivePutMutation,
    geometryUnarchivePutMutation,
    geometryDownloadQuery,
    downloadMutation,
    geometryCloneMutation,
    uploadQuery,
    usersQuery,
    organizationsQuery,
  } = useAdminGeometriesQueries();

  const [geometry, setGeometry] = useState<APIModels.ApGeometryCollection>();

  useEffect(() => {
    setGeometry(geometryQuery.data);
    setSelectedGeometryOrganization(
      organizationsQuery.data?.find(
        (o) => o.id === geometryQuery.data?.customerId
      )
    );
  }, [geometryQuery.data, organizationsQuery.data]);

  const archiveGeometry = useCallback(() => {
    if (geometryArchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to archive this geometry?'
    );
    if (!confirm) {
      return;
    }

    geometryArchivePutMutation.mutate({
      id: Number(geometryId),
      data: {
        ...geometryQuery.data,
        archived: true,
      },
    });
  }, [geometryArchivePutMutation, geometryQuery.data, geometryId]);

  const unarchiveGeometry = useCallback(() => {
    if (geometryUnarchivePutMutation.isPending) {
      return;
    }

    const confirm = window.confirm(
      'Are you sure you want to restore this geometry?'
    );
    if (!confirm) {
      return;
    }

    geometryUnarchivePutMutation.mutate({
      id: Number(geometryId),
      data: {
        ...geometryQuery.data,
        archived: false,
      },
    });
  }, [geometryUnarchivePutMutation, geometryQuery.data, geometryId]);

  const saveGeometry = useCallback(async () => {
    if (geometryPutMutation.isPending) {
      return;
    }

    if (await geometryFormValidation.hasErrors()) {
      return;
    }

    geometryPutMutation.mutate({
      id: Number(geometryId),
      data: geometry as APIModels.ApGeometryCollection,
    });
  }, [geometry, geometryFormValidation, geometryPutMutation, geometryId]);

  const [mapController, setMapController] = useState<OpenLayerMapController>();
  const [tileLayer, setTileLayer] = useState<VectorTileLayer>();

  const [selectedGeometryOrganization, setSelectedGeometryOrganization] =
    useState<APIModels.Customer>();

  useEffect(() => {
    setGeometry((prev) => ({
      ...prev,
      customerId: selectedGeometryOrganization?.id,
    }));
  }, [selectedGeometryOrganization]);

  const featureCache = useRef<Record<number, Feature<Geometry>>>({});
  const featurePointsSource = useMemo(
    () => new VectorSource<Feature<Point>>(),
    []
  );

  useEffect(() => {
    if (!uploadQuery.data?.id) {
      return;
    }
    const token = accessToken || '';
    // hack alert
    // we have to override the layer type here
    const layer = {
      layerType: {} as any,
      entityId: NaN,
      extent: undefined as Extent | undefined,
    };
    layer.layerType.name = LayerTypeName.GeometryCollection;
    layer.layerType.mapLayer = 'Agermetrix:Geometry_Collections';
    layer.layerType.displayName = 'Geometries';
    layer.entityId = geometry?.id || NaN;
    layer.extent = geometry?.extent || undefined;
    const wmsLayer = toStateLayer(layer) as WmsVectorMapLayer;
    const wmsSrc = new TileWMS({
      url: wmsLayer.wmsUrl,
      params: {
        LAYERS: wmsLayer.layers,
        viewparams: wmsLayer.params,
        FORMAT: 'application/vnd.mapbox-vector-tile',
      },
      crossOrigin: 'crossOrigin',
    });

    const tileLyrSrc = new VectorTileSource({
      format: new MVT(),
      tileUrlFunction: wmsSrc.getTileUrlFunction(),
      tileGrid: wmsSrc.getTileGrid() || undefined,
      tileSize: [512, 512],
      tileLoadFunction: (tile: any, url: any) => {
        loadTile(
          token,
          featureCache,
          featurePointsSource,
          tile,
          url,
          wmsLayer.id
        );
      },
      overlaps: false,
    });

    const tileLyr = new VectorTileLayer({
      extent:
        getWidth(wmsLayer.extent) > 0 && getHeight(wmsLayer.extent) > 0
          ? transformExtent(wmsLayer.extent, 'EPSG:4326', 'EPSG:3857')
          : undefined,
      source: tileLyrSrc,
      visible: true,
      className: '',
      properties: {
        wmsLayerId: wmsLayer.id,
      },
    });
    setTileLayer(tileLyr);
  }, [uploadQuery.data, accessToken]);

  useEffect(() => {
    if (!mapController || !tileLayer) {
      return;
    }

    const extent = tileLayer?.getExtent();
    if (!extent) {
      return;
    }

    mapController?.zoomMapToExtent?.(extent);
  }, [tileLayer, mapController, geometry]);

  const [selectedCloneUser, setSelectedCloneUser] = useState<APIModels.User>();
  const [selectedCloneOrganization, setSelectedCloneOrganization] =
    useState<APIModels.Customer>();

  const cloneGeometry = useCallback(async () => {
    if (geometryCloneMutation.isPending) {
      return;
    }

    if (await cloneGeometryFormValidation.hasErrors()) {
      return;
    }

    geometryCloneMutation.mutate(
      {
        data: {
          assignToUserUuid: selectedCloneUser?.id ?? undefined,
          customerId: selectedCloneOrganization?.id,
          geometryCollectionIds: [Number(geometryId)],
        },
      },
      {
        onSuccess: () => {
          setSelectedCloneOrganization(undefined);
          setSelectedCloneUser(undefined);
        },
      }
    );
  }, [
    cloneGeometryFormValidation,
    geometryCloneMutation,
    selectedCloneOrganization,
    selectedCloneUser,
    geometryId,
  ]);

  return (
    <div className="flex flex-col h-full w-full pt-4 overflow-auto">
      <div className="px-4">
        <BreadCrumbs
          items={[
            {
              label: 'Platform',
              path: isViteApp ? '/app/admin/platform' : '/admin',
            },
            {
              label: 'Geometries',
              path: isViteApp
                ? '/app/admin/platform/geometries'
                : '/admin/geometries',
              params,
            },
          ]}
        />
      </div>
      <div className="flex flex-row gap-2 justify-start items-center px-4 py-2">
        <Button.Back
          id="geometries-details-back-button"
          onClick={() => {
            if (isViteApp) {
              navigate('/app/admin/platform/geometries' + params);
            } else {
              navigate('/admin/geometries' + params);
            }
          }}
        />
        <h1 className="text-3xl font-bold">{geometryQuery.data?.name}</h1>
      </div>
      {geometryQuery.isLoading ? (
        <PageLoadingState />
      ) : geometryQuery.isError ? (
        <PageErrorState
          entityName="geometry"
          pluralEntityName="geometries"
          statusCode={geometryQuery.error?.response?.status ?? 500}
          tryAgainCallback={() => {
            geometryQuery.refetch();
          }}
          tryAgainLoading={geometryQuery.isFetching}
          navigateBackCallback={() =>
            navigate(
              isViteApp
                ? '/app/admin/platform/geometries' + params
                : '/admin/geometries' + params
            )
          }
        />
      ) : (
        <div className="p-4 h-full w-full flex flex-row">
          <div className="w-full flex flex-col max-w-lg gap-2">
            <Input.Text.Single
              id="name-input"
              label={<Input.Label label="Name" required />}
              value={geometry?.name || ''}
              setValue={(name) => {
                setGeometry({ ...geometry, name });
              }}
              error={
                <Input.Error
                  error={geometryFormValidation.errors['name-input']}
                />
              }
              validation={{
                validationState: geometryFormValidation,
                validators: [Input.validators.required('Name')],
              }}
            />

            <Input.Select.Single
              id="organization-select"
              label={<Input.Label label="Organization" required />}
              error={
                <Input.Error
                  error={geometryFormValidation.errors['organization-select']}
                />
              }
              loading={organizationsQuery.isLoading}
              options={organizationsQuery.data ?? []}
              optionBuilder={(o) =>
                o.customerDisplayName ?? o.customerName ?? 'Unknown'
              }
              value={selectedGeometryOrganization}
              setValue={(o) => {
                setSelectedGeometryOrganization(o);
              }}
              title="Organization"
              validation={{
                validationState: geometryFormValidation,
                validators: [Input.validators.required('Organization')],
              }}
            />

            <div className="w-full flex flex-row justify-end gap-2 py-4">
              <Button.Secondary
                id="download-geometry-button"
                label={'Download'}
                onClick={() => {
                  downloadMutation.mutate();
                }}
                disabled={
                  geometryDownloadQuery.isPending ||
                  geometryDownloadQuery.data?.downloadUrl === undefined ||
                  geometryQuery.data?.name === undefined
                }
                loading={downloadMutation.isPending}
              />

              {geometry?.archived ? (
                <Button.Secondary
                  id="restore-geometry-button"
                  label="Restore"
                  onClick={() => {
                    unarchiveGeometry();
                  }}
                  loading={geometryUnarchivePutMutation.isPending}
                />
              ) : (
                <Button.Danger
                  id="archive-geometry-button"
                  label="Archive"
                  onClick={() => {
                    archiveGeometry();
                  }}
                  loading={geometryArchivePutMutation.isPending}
                />
              )}

              <Button.Primary
                id="save-geometry-button"
                label={'Save'}
                onClick={saveGeometry}
                loading={geometryPutMutation.isPending}
              />
            </div>

            <EntityDetailsSection
              items={[
                {
                  label: 'ID',
                  value: geometry?.id,
                },
                {
                  label: 'UUID',
                  value: geometry?.uuid,
                },
                {
                  label: 'Owner UUID',
                  value: geometry?.owner,
                },
                {
                  label: 'Created Date',
                  value: formatDateAndTime(geometry?.createDatetime),
                },
                {
                  label: 'Last Updated',
                  value: formatDateAndTime(geometry?.updateDatetime),
                },
                {
                  label: 'Updated By UUID',
                  value: geometry?.updatedById,
                },
              ]}
            />

            <div className="flex flex-col items-stretch w-full gap-2">
              <Input.Select.Single
                id="clone-user-select"
                label={<Input.Label label="User" required />}
                error={
                  <Input.Error
                    error={
                      cloneGeometryFormValidation.errors['clone-user-select']
                    }
                  />
                }
                loading={usersQuery.isLoading}
                options={usersQuery.data ?? []}
                optionBuilder={(o) =>
                  `${o.userProfiles?.[0].firstName} ${o.userProfiles?.[0].lastName}`.trim()
                }
                value={selectedCloneUser}
                setValue={(o) => {
                  setSelectedCloneUser(o);
                }}
                title="User"
                validation={{
                  validationState: cloneGeometryFormValidation,
                  validators: [Input.validators.required('User')],
                }}
              />
              <Input.Select.Single
                id="clone-organization-select"
                label={<Input.Label label="Organization" required />}
                error={
                  <Input.Error
                    error={
                      cloneGeometryFormValidation.errors[
                        'clone-organization-select'
                      ]
                    }
                  />
                }
                options={organizationsQuery.data ?? []}
                optionBuilder={(o) =>
                  o.customerDisplayName ?? o.customerName ?? 'Unknown'
                }
                value={selectedCloneOrganization}
                setValue={(o) => {
                  setSelectedCloneOrganization(o);
                }}
                loading={organizationsQuery.isLoading}
                title="Organization"
                validation={{
                  validationState: cloneGeometryFormValidation,
                  validators: [Input.validators.required('Organization')],
                }}
              />
              <div className="flex flex-row justify-end w-full py-4">
                <Button.Primary
                  id="clone-geometry-button"
                  label={'Clone'}
                  loading={geometryCloneMutation.isPending}
                  onClick={cloneGeometry}
                />
              </div>
            </div>
          </div>
          <div className="w-full pt-1 pl-2">
            <div className="overflow-hidden rounded-md w-full h-full">
              <OpenLayerMap
                id="admin-geometries-details-map"
                bingKey={environment.bing_api_key}
                controller={setMapController}
                mapLayers={{
                  used: [OpenMapLayer.Aerial],
                  initial: OpenMapLayer.Aerial,
                }}
                vectorTileLayers={tileLayer ? [tileLayer] : undefined}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
